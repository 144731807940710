import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
  SaveButton,
  Toolbar,
  required,
  email,
  choices,
  minLength,
  BooleanInput,
  regex,
  NumberInput
} from "react-admin";

const UserCreate = (props) => {

  const TranslatedToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Salvar"/>
    </Toolbar>
  );

  const validateNumber = (message = "O campo deve conter apenas números") => (value) => {
    if (!value) {
        return message;
    }
    const numberPattern = /^[0-9]+$/;
    if (!numberPattern.test(value)) {
        return message;
    }
    return undefined;
};

  const validateName = [required('Campo obrigatório'), minLength(2, 'Mínimo dois caracteres')];
  const validateLastName = [required('Campo obrigatório'), minLength(2, 'Mínimo dois caracteres')];
  const validateEmail = [required('Campo obrigatório'), email('Email inválido')];
  const validateCPFCNPJ = [required('Campo obrigatório'), regex(/^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/, 'Digite um CPF ou CNPJ válido')]
  const validatePassword = [required('Campo obrigatório'), minLength(6, 'Mínimo 6 caracteres')];
  const validateZipCode = [required("Campo obrigatório"), minLength(8, "Digite um CEP válido")];
  const validateStreet = [required('Campo obrigatório')]
  const validateStreetNumber = [required('Campo obrigatório'), validateNumber("O campo deve conter apenas números")]
  const validateNeighborhood = [required("Campo obrigatório")]
  const validateBirthDate = [required("Campo obrigatório")]
  const validateCity = [required("Campo obrigatório")]
  const validateState = [required("Campo obrigatório")]
  const validateGender = choices(['Masculino', 'Feminino'], 'Escolha o gênero');
  const validateRole = choices(['client', 'professional', 'admin', 'company', 'manager', 'secretary'], 'Escolha a função');

  return (
    <Create title="Cadastrar usuário" {...props}>
      <SimpleForm redirect="list" toolbar={<TranslatedToolbar/>}>
        <TextInput validate={validateName} source="name" label="Nome" />
        <TextInput validate={validateLastName} source="last_name" label="Sobrenome" />
        <TextInput validate={validateCPFCNPJ} source="cpf_cnpj" label="CPF ou CNPJ" />
        <TextInput validate={validateCity} source="city" label="Cidade" />
        <TextInput validate={validateState} source="state" label="Estado" />
        <TextInput validate={validateZipCode} source="zip_code" label="CEP" />
        <TextInput validate={validateStreet} source="street" label="Logradouro" />
        <TextInput validate={validateNeighborhood} source="neighborhood" label="Bairro" />
        <NumberInput validate={validateStreetNumber} source="street_number" label="Número" />
        <TextInput source="complement" label="Complemento" />
        <TextInput validate={validateEmail} source="email" label="Email" />
        <DateInput
          locales="pt-BR"
          source="birth"
          validate={validateBirthDate}
          label="Data de nascimento"
        />
        <SelectInput validate={validateGender} source="gender" label="Gênero" choices={[
            { id: 'Masculino', name: 'Masculino' },
            { id: 'Feminino', name: 'Feminino' },
        ]} />
        <TextInput validate={validatePassword} source="password" label="Senha" />
        <SelectInput validate={validateRole} source="role" label="Função" choices={[
            { id: 'client', name: 'Cliente' },
            { id: 'professional', name: 'Profissional' },
            { id: 'admin', name: 'Administrador' },
            { id: 'company', name: 'Empresa' },
            { id: 'manager', name: 'Gerente'},
            { id: 'secretary', name: 'Secretária' }
        ]} />
        <BooleanInput source="active" label="Ativo"/>
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
