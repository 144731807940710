import React, { useState, useEffect, useCallback } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  SaveButton,
  Toolbar,
  ImageField,
  ImageInput,
  required,
  BooleanInput
} from "react-admin";
import TinyMCEInput from "../helpers/TinyMCEInput";

const SpaceCreate = (props) => {

  const TranslatedToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Salvar"/>
    </Toolbar>
  );

  const validateReq = required('Campo obrigatório');

  return (
    <Create title="Novo espaço no Mundo Akar" {...props}>
      <SimpleForm redirect="list" toolbar={<TranslatedToolbar/>}>
        <TextInput validate={validateReq} source="name" label="Nome" />
        <ImageInput  source="thumbnail" label="Thumbnail" accept="image/*" placeholder={<p> Arraste e solte ou clique para escolher a imagem </p>}>
            <ImageField source="thumbnail" title="Thumbnail" />
        </ImageInput>
        <TinyMCEInput source="description" label="Descrição/Detalhes" validate={required()} />
        <SelectInput validate={validateReq} source="category" label="Categoria" choices={[
            { id: 'Coworking', name: 'Coworking' },
            { id: 'Eventos', name: 'Eventos' },
            { id: 'Café', name: 'Café' },
            { id: 'Jardim', name: 'Jardim' },
        ]} />
        <ImageInput source="image1" label="Nova Imagem 1" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image1" title="Nova Imagem 1" />
        </ImageInput>
        <ImageInput source="image2" label="Nova Imagem 2" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image2" title="Nova Imagem 2" />
        </ImageInput>
        <ImageInput source="image3" label="Nova Imagem 3" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image3" title="Nova Imagem 3" />
        </ImageInput>
        <ImageInput source="image4" label="Nova Imagem 4" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image4" title="Nova Imagem 4" />
        </ImageInput>
        <ImageInput source="image5" label="Nova Imagem 5" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image5" title="Nova Imagem 5" />
        </ImageInput>
        <ImageInput source="image6" label="Nova Imagem 6" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image6" title="Nova Imagem 6" />
        </ImageInput>
        <BooleanInput source="is_active" label="Ativo"/>
      </SimpleForm>
    </Create>
  );
};

export default SpaceCreate;
