import React, { useCallback, useEffect, useState } from "react";
import {
  Create,
  SimpleForm,
  NumberInput,
  SelectInput,
  DateTimeInput,
  required,
  minValue,
  choices,
} from "react-admin";
import axios from "axios";
import { Constants } from "../../constants";

const SocialScheduleCreate = (props) => {
  const [serviceChoices, setServiceChoices] = useState([]);
  const [professionalChoices, setProfessionalChoices] = useState([]);

  const getServices = useCallback(async () => {
    try {
      const response = await axios.get(Constants.ApiUrl + "/services", {
        params: {
          range: "[0,1000]",
          sort: '["name", "ASC"]',
          filter: {
            type: "SOCIAL",
          },
        },
      });

      const auxServices = response.data;

      const transformedServices = auxServices.map((service) => {
        return { id: service.id, name: `${service.name}` };
      });

      setServiceChoices(transformedServices);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getProfessionals = useCallback(async () => {
    try {
      const response = await axios.get(
        Constants.ApiUrl + "/users/professionals", {
          params: {
            filter: {
              is_blocked: false
            },
          }
        }
      );

      const auxProfessionals = response.data;

      const transformedProfessionals = auxProfessionals.map((professional) => {
        return {
          id: professional.id,
          name: `${professional.name} ${professional.last_name}`,
        };
      });

      setProfessionalChoices(transformedProfessionals);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getServices();
    getProfessionals();
  }, [getProfessionals, getServices]);

  const validatePrice = [
    required("Campo obrigatório"),
    minValue(5, "Valor mínimo R$5"),
  ];
  const validateReq = required("Campo obrigatório");
  const validateType = choices(["Presencial", "Online"], "Escolha o tipo");
  const validateClientType = choices(["Individual", "Grupo"], "Escolha o tipo");
  const validateMinClients = [
    required("Campo obrigatório"),
    minValue(1, "Mínimo 1 participante"),
  ];
  const validadeMinMinutes = [
    required("Campo obrigatório"),
    minValue(5, "Mínimo 5 minutos"),
  ];

  return (
    <Create title="Cadastrar nova consulta do social" {...props}>
      <SimpleForm redirect="list">
        <SelectInput
          validate={validateReq}
          source="service"
          label="Serviço"
          choices={serviceChoices}
        />
        <SelectInput
          validate={validateReq}
          source="professional"
          label="Profissional"
          choices={professionalChoices}
        />
        <DateTimeInput
          validate={validateReq}
          locales="pt-BR"
          source="date"
          label="Data e hora"
        />
        <NumberInput
          min={5}
          defaultValue={30}
          validate={validadeMinMinutes}
          source="duration"
          label="Duração (minutos)"
        />
        <DateTimeInput
          locales="pt-BR"
          source="sold_until"
          label="Limite de horário para venda"
        />
        <SelectInput
          validate={validateClientType}
          source="client_type"
          label="Individual ou Grupo"
          choices={[
            { id: "Individual", name: "Individual" },
            { id: "Grupo", name: "Grupo" },
          ]}
        />
        <NumberInput
          validate={validateMinClients}
          source="max_clients"
          label="Máximo de clientes"
        />
        <SelectInput
          validate={validateType}
          source="type"
          label="Presencial ou Online"
          choices={[
            { id: "Presencial", name: "Presencial" },
            { id: "Online", name: "Online" },
          ]}
        />
        <NumberInput validate={validatePrice} source="price" label="Preço" />
      </SimpleForm>
    </Create>
  );
};

export default SocialScheduleCreate;
