import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  EditButton,
  DeleteButton,
  Filter,
  SelectInput,
  TextInput,
  DateField,
  DateInput,
  FunctionField
} from "react-admin";
import Typography from "@material-ui/core/Typography";

const SpaceList = (props) => {

  const ListFilter = props => (
    <Filter {...props}>
        <TextInput source="title" label="Título" />
        <TextInput source="description" label="Descrição"/>
        <SelectInput source="category" label="Categoria" choices={[
            { id: 'coworking', name: 'Coworking' },
            { id: 'eventos', name: 'Eventos' },
            { id: 'cafe', name: 'Café' },
            { id: 'jardim', name: 'Jardim' },
        ]} />
        <DateInput source="createdAt" locales="fr-FR" label="Publicado em"/>
    </Filter>
  );

  const StatusField = (record) => {
    return (
      <Typography color={record?.is_active ? "secondary" : "textSecondary"}>
        {record?.is_active ? 'Ativo' : 'Inativo'}
      </Typography>
    )
  }

  return (
    <List title="Listar espaços do Mundo Akar" filters={<ListFilter />} {...props}>
      <Datagrid rowClick={'show'}>
        <TextField source="id" label="Id" />
        <TextField source="name" label="Nome" />
        <ChipField source="category" label="Categoria" />
        <FunctionField label="Status" render={StatusField} />
        <DateField locales="fr-FR" source="createdAt" label="Publicado em"/>
        <EditButton basePath="/spaces" />
        <DeleteButton basePath="/spaces" />
      </Datagrid>
    </List>
  );
};

export default SpaceList;
