export const Constants = {

  //Produção
  // ApiUrl: "https://mundo-akar-backend.herokuapp.com/api",
  // ApiUrl: "https://api.mundoakar.com.br/api",
  ApiUrl: "https://mundo-akar-backend-dev.mg4.andreconjo.com/api",

  //Dev
  // ApiUrl: "http://localhost:8080/api",
  // ApiUrl: "https://mundoakar-dev-backend.herokuapp.com/api",
};
