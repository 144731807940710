import React, { useState, useCallback, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  SelectInput,
  SaveButton,
  Toolbar,
  ImageField,
  Labeled,
  FormDataConsumer,
  required,
  SelectArrayInput,
  BooleanInput
} from "react-admin";
import TinyMCEInput from "../helpers/TinyMCEInput";
import TagQuickCreateButton from "../custom/TagQuickCreateButton";
import axios from 'axios';
import { Constants } from '../../constants';

const PermissionEdit = (props) => {


  const TranslatedToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Salvar" />
    </Toolbar>
  );

  return (
    <Edit title="Editar Permissões" {...props}>
      <SimpleForm toolbar={<TranslatedToolbar/>}>
        <TextInput disabled source="role" label="Função" />
        <BooleanInput source="create_users" label="Criar usuários"/>
        <BooleanInput source="edit_users" label="Editar usuários"/>
        <BooleanInput source="delete_users" label="Excluir usuários"/>
        <BooleanInput source="create_services" label="Criar Terapias"/>
        <BooleanInput source="edit_services" label="Editar Terapias"/>
        <BooleanInput source="delete_services" label="Excluir Terapias"/>
        <BooleanInput source="create_posts" label="Publicar posts no blog"/>
        <BooleanInput source="edit_posts" label="Editar posts do blog"/>
        <BooleanInput source="delete_posts" label="Excluir posts do blog"/>
        <BooleanInput source="create_social_schedules" label="Criar agendamentos do Social"/>
        <BooleanInput source="edit_social_schedules" label="Editar agendamentos do Social"/>
        <BooleanInput source="delete_social_schedules" label="Excluir agendamento do Social"/>
        <BooleanInput source="create_coupons" label="Criar cupons"/>
        <BooleanInput source="edit_coupons" label="Editar cupons"/>
        <BooleanInput source="delete_coupons" label="Excluir cupons"/>
        <BooleanInput source="create_plans" label="Criar Planos"/>
        <BooleanInput source="edit_plans" label="Editar Planos"/>
        <BooleanInput source="delete_plans" label="Excluir Planos"/>
        <BooleanInput source="create_events" label="Criar Eventos"/>
        <BooleanInput source="edit_events" label="Editar Eventos"/>
        <BooleanInput source="delete_events" label="Excluir Eventos"/>
        <BooleanInput source="create_rooms" label="Criar Salas"/>
        <BooleanInput source="edit_rooms" label="Editar Salas"/>
        <BooleanInput source="delete_rooms" label="Excluir Salas"/>
        <BooleanInput source="create_spaces" label="Criar Espaços"/>
        <BooleanInput source="edit_spaces" label="Editar Espaços"/>
        <BooleanInput source="delete_spaces" label="Excluir Espaços"/>
        <BooleanInput source="create_user_services" label="Habilitar terapias para um profissional"/>
        <BooleanInput source="edit_user_services" label="Editar terapias para um profissional"/>
        <BooleanInput source="delete_user_services" label="Remover permissões de terapias de um profissional"/>
        <BooleanInput source="reschedule" label="Pode reagendar consultas"/>
        <BooleanInput source="cancel_schedules" label="Pode cancelar consultas"/>
        <BooleanInput source="schedule_a_client" label="Pode marcar uma consulta"/>
        <BooleanInput source="edit_permissions" label="Editar Permissões"/>
        <BooleanInput source="add_clients_to_plans" label="Habilitar plano de um cliente"/>
        <BooleanInput source="remove_clients_from_plans" label="Remover cliente de um plano"/>
        <BooleanInput source="edit_galery" label="Editar galeria"/>
      </SimpleForm>
    </Edit>
  );
};

export default PermissionEdit;