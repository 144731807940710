import React, { useState, useCallback, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  SelectInput,
  SaveButton,
  Toolbar,
  ImageField,
  Labeled,
  FormDataConsumer,
  required,
  SelectArrayInput,
  BooleanInput
} from "react-admin";
import TinyMCEInput from "../helpers/TinyMCEInput";
import TagQuickCreateButton from "../custom/TagQuickCreateButton";
import axios from 'axios';
import { Constants } from '../../constants';

const RoomEdit = (props) => {

  const TranslatedToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Salvar" />
    </Toolbar>
  );

  const validateReq = required('Campo obrigatório');

  return (
    <Edit title="Editar sala" {...props}>
      <SimpleForm toolbar={<TranslatedToolbar/>}>
        <TextInput validate={validateReq} source="name" label="Nome da sala" />
        <ImageInput source="thumbnail" label="Thumbnail" accept="image/*" mulitple={false} placeholder={<p> Arraste e solte a imagem ou clique para alterar a thumbnail original </p>}>
            <ImageField source="thumbnail" title="Nova thumbnail" />
        </ImageInput>
        <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.src) {
                    return (
                        <div>
                        <Labeled label="Thumbnail original">
                            <ImageField source="thumbnail_url" {...rest}/>
                        </Labeled>
                        </div>
                    );
                }
            }}
        </FormDataConsumer>
        <TinyMCEInput source="description" label="Descrição/Detalhes" validate={required()} />

        <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.src) {
                    return (
                        <div>
                        <Labeled label="Imagem 1 atual">
                            <ImageField source="image1_url" {...rest}/>
                        </Labeled>
                        </div>
                    );
                }
            }}
        </FormDataConsumer>
        <ImageInput source="image1" label="Nova Imagem 1" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image1" title="Nova Imagem 1" />
        </ImageInput>

        <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.src) {
                    return (
                        <div>
                        <Labeled label="Imagem 2 atual">
                            <ImageField source="image2_url" {...rest}/>
                        </Labeled>
                        </div>
                    );
                }
            }}
        </FormDataConsumer>
        <ImageInput source="image2" label="Nova Imagem 2" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image2" title="Nova Imagem 2" />
        </ImageInput>

        <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.src) {
                    return (
                        <div>
                        <Labeled label="Imagem 3 atual">
                            <ImageField source="image3_url" {...rest}/>
                        </Labeled>
                        </div>
                    );
                }
            }}
        </FormDataConsumer>
        <ImageInput source="image3" label="Nova Imagem 3" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image3" title="Nova Imagem 3" />
        </ImageInput>

        <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.src) {
                    return (
                        <div>
                        <Labeled label="Imagem 4 atual">
                            <ImageField source="image4_url" {...rest}/>
                        </Labeled>
                        </div>
                    );
                }
            }}
        </FormDataConsumer>
        <ImageInput source="image4" label="Nova Imagem 4" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image4" title="Nova Imagem 4" />
        </ImageInput>

        <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.src) {
                    return (
                        <div>
                        <Labeled label="Imagem 5 atual">
                            <ImageField source="image5_url" {...rest}/>
                        </Labeled>
                        </div>
                    );
                }
            }}
        </FormDataConsumer>
        <ImageInput source="image5" label="Nova Imagem 5" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image5" title="Nova Imagem 5" />
        </ImageInput>

        <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.src) {
                    return (
                        <div>
                        <Labeled label="Imagem 6 atual">
                            <ImageField source="image6_url" {...rest}/>
                        </Labeled>
                        </div>
                    );
                }
            }}
        </FormDataConsumer>
        <ImageInput source="image6" label="Nova Imagem 6" accept="image/*" placeholder={<p> Arraste e solte ou clique para inserir uma nova imagem </p>}>
            <ImageField source="image6" title="Nova Imagem 6" />
        </ImageInput>
        <BooleanInput source="is_active" label="Ativo"/>
      </SimpleForm>
    </Edit>
  );
};

export default RoomEdit;
