import {
    SimpleForm,
    TextInput,
    Edit,
    NumberInput,
    minValue,
    BooleanInput,
    required,
    Create,
    SelectField,
    SelectInput
  } from "react-admin";
  
  const validateReq = required("Campo obrigatório");
  const validateMinValue = [minValue(0, "Não pode ser negativo")];
  
  const InvoiceTaxCreate = (props) => {
    return (
      <Create title="Criar taxa" {...props}>
        <SimpleForm>
          <BooleanInput source="retain_iss" label="Reter ISS" />
          <NumberInput validate={validateMinValue} source="iss" label="ISS" />
          <NumberInput
            validate={validateMinValue}
            source="cofins"
            label="Cofins"
          />
          <NumberInput validate={validateMinValue} source="csll" label="CSLL" />
          <NumberInput validate={validateMinValue} source="ir" label="IR" />
          <NumberInput validate={validateMinValue} source="inss" label="INSS" />
          <NumberInput validate={validateMinValue} source="pis" label="PIS" />
          <TextInput
            validate={validateReq}
            source="municipal_service_id"
            label="ID Serviço Municipal"
          />
          <TextInput
            validate={validateReq}
            source="municipal_service_code"
            label="Código Serviço Municipal"
          />
          <TextInput
            validate={validateReq}
            source="municipal_service_name"
            label="Nome Serviço Municipal"
          />
          <SelectInput source="category" label={"Categoria"} choices={[{
            id: "Social", name: "Social"
          }, 
          {
            id: "Geral", name: "Geral"
          }
          ]} />
          <BooleanInput source="active" label="Emissão ativa" />
        </SimpleForm>
      </Create>
    );
  };
  
  export default InvoiceTaxCreate;
  