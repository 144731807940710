import React from "react";
import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import {
  PostAdd,
  People,
  AttachMoney,
  Description,
  PhotoLibrary,
  RoomService,
  Schedule,
  ConfirmationNumber,
  NotificationsActive,
  MeetingRoom,
  Warning,
  CallSplit,
  Pages,
  AssignmentInd,
  Feedback,
  Comment,
} from "@material-ui/icons";
import LocalOffer from "@material-ui/icons/LocalOffer";
import portugueseMessages from "./i18n/i18nProvider";
import imageUploadProvider from "./imageUploadProvider";
import authProvider from "./authProvider";

import Menu from "./components/custom/Menu";
import CustomRoutes from "./components/custom/CustomRoutes";

import UserList from "./components/users/UserList";
import UserCreate from "./components/users/UserCreate";
import UserEdit from "./components/users/UserEdit";
import PostCreate from "./components/posts/PostCreate";
import PostEdit from "./components/posts/PostEdit";
import PostList from "./components/posts/PostList";
import ServiceEdit from "./components/services/ServiceEdit";
import ServiceCreate from "./components/services/ServiceCreate";
import ServiceList from "./components/services/ServiceList";
import SocialScheduleCreate from "./components/socialSchedules/SocialScheduleCreate";
import SocialScheduleList from "./components/socialSchedules/SocialScheduleList";
import SocialScheduleEdit from "./components/socialSchedules/SocialScheduleEdit";
import UserShow from "./components/users/UserShow";
import PostShow from "./components/posts/PostShow";
import ServiceShow from "./components/services/ServiceShow";
import SocialScheduleShow from "./components/socialSchedules/SocialScheduleShow";
import CouponShow from "./components/coupons/CouponShow";
import CouponEdit from "./components/coupons/CouponEdit";
import CouponCreate from "./components/coupons/CouponCreate";
import CouponList from "./components/coupons/CouponList";
import SocialSchedulesClientsList from "./components/socialSchedulesClients/SocialSchedulesClientsList";
import PaymentList from "./components/payments/PaymentList";
import PaymentShow from "./components/payments/PaymentShow";
import OrderList from "./components/orders/OrderList";
import GaleryShow from "./components/galery/GaleryShow";
import GaleryEdit from "./components/galery/GaleryEdit";
import GaleryList from "./components/galery/GaleryList";
import UserServicesList from "./components/usersServices/usersServicesList";
import WaitList from "./components/waitList/waitList";
import PlanList from "./components/plans/PlanList";
import PlanShow from "./components/plans/PlanShow";
import PlanEdit from "./components/plans/PlanEdit";
import PlanCreate from "./components/plans/PlanCreate";
import TagCreate from "./components/tags/TagCreate";
import TagList from "./components/tags/TagList";
import EventCreate from "./components/events/EventCreate";
import EventList from "./components/events/EventList";
import EventShow from "./components/events/EventShow";
import EventEdit from "./components/events/EventEdit";
import RoomCreate from "./components/rooms/RoomCreate";
import RoomList from "./components/rooms/RoomList";
import RoomShow from "./components/rooms/RoomShow";
import RoomEdit from "./components/rooms/RoomEdit";
import SpaceCreate from "./components/spaces/SpaceCreate";
import SpaceList from "./components/spaces/SpaceList";
import SpaceShow from "./components/spaces/SpaceShow";
import SpaceEdit from "./components/spaces/SpaceEdit";
import PermissionList from "./components/permissions/PermissionsList";
import PermissionEdit from "./components/permissions/PermissionsEdit";
import AdvertisementCreate from "./components/advertisements/AdvertisementCreate";
import AdvertisementList from "./components/advertisements/AdvertisementList";
import AdvertisementEdit from "./components/advertisements/AdvertisementEdit";
import AdvertisementShow from "./components/advertisements/AdvertisementShow";
import PageEdit from "./components/pages-content/PageEdit";
import PageShow from "./components/pages-content/PageShow";
import PageList from "./components/pages-content/PageList";
import PageCreate from './components/pages-content/PageCreate'
import ScheduleList from "./components/generalSchedules/SchedulesList";
import SchedulesShow from "./components/generalSchedules/SchedulesShow";
import SugestionsList from "./components/sugestions/SugestionsList";
import SugestionsShow from "./components/sugestions/SugestionsShow";
import CommentShow from "./components/comments/CommentShow";
import CommentList from "./components/comments/CommentList";
import CommentEdit from "./components/comments/CommentEdit";
import InvoiceTaxList from "./components/invoiceTaxes/InvoiceTaxList";
import InvoiceTaxShow from "./components/invoiceTaxes/InvoiceTaxShow";
import InvoiceTaxEdit from "./components/invoiceTaxes/InvoiceTaxEdit";
import InvoiceList from "./components/invoices/InvoicesList";
import InvoicesShow from "./components/invoices/InvoicesShow";
import GalleryCreate from "./components/galery/GaleryCreate";
import TagEdit from "./components/tags/TagEdit";
import InvoiceTaxCreate from "./components/invoiceTaxes/InvoiceTaxCreate";

const messages = {
  pt: portugueseMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "pt");

function App() {
  return (
    <Admin
      customRoutes={CustomRoutes}
      menu={Menu}
      title="Mundo Akar Admin"
      dataProvider={imageUploadProvider}
      authProvider={authProvider}
      locale="pt"
      i18nProvider={i18nProvider}
    >
      <Resource
        options={{ label: "Agenda Akar Social" }}
        icon={Schedule}
        name="socialSchedules"
        show={SocialScheduleShow}
        edit={SocialScheduleEdit}
        create={SocialScheduleCreate}
        list={SocialScheduleList}
      />
      <Resource
        options={{ label: "Agendamentos Geral" }}
        icon={People}
        name="schedules"
        list={ScheduleList}
        show={SchedulesShow}
      />
      <Resource
        options={{ label: "Anúncios" }}
        icon={CallSplit}
        name="advertisements"
        edit={AdvertisementEdit}
        show={AdvertisementShow}
        create={AdvertisementCreate}
        list={AdvertisementList}
      />
      <Resource
        options={{ label: "Clientes" }}
        icon={People}
        name="socialSchedulesClients"
        list={SocialSchedulesClientsList}
      />
      <Resource
        options={{ label: "Cupons" }}
        icon={ConfirmationNumber}
        name="coupons"
        show={CouponShow}
        edit={CouponEdit}
        create={CouponCreate}
        list={CouponList}
      />
      <Resource
        options={{ label: "Comentários" }}
        icon={Comment}
        name="comments"
        show={CommentShow}
        list={CommentList}
        edit={CommentEdit}
      />
      <Resource
        options={{ label: "Eventos" }}
        icon={Schedule}
        name="events"
        create={EventCreate}
        list={EventList}
        show={EventShow}
        edit={EventEdit}
      />
      <Resource
        options={{ label: "Espaços" }}
        icon={MeetingRoom}
        name="spaces"
        create={SpaceCreate}
        list={SpaceList}
        show={SpaceShow}
        edit={SpaceEdit}
      />
      <Resource
        options={{ label: "Galeria" }}
        icon={PhotoLibrary}
        name="galery"
        list={GaleryList}
        show={GaleryShow}
        edit={GaleryEdit}
        create={GalleryCreate}
      />
      <Resource
        options={{ label: "Lista de espera" }}
        icon={NotificationsActive}
        name="waitlistclients"
        list={WaitList}
      />
      <Resource
        options={{ label: "Pagamentos" }}
        icon={AttachMoney}
        name="payments"
        show={PaymentShow}
        list={PaymentList}
      />
      {/* <Resource
        options={{ label: "Páginas" }}
        icon={Pages}
        name="pages"
        list={PageList}
        show={PageShow}
        edit={PageEdit}
      /> */}
      <Resource
        options={{ label: "Páginas" }}
        icon={Pages}
        name="pages-content"
        list={PageList}
        show={PageShow}
        edit={PageEdit}
        create={PageCreate}
      />
      <Resource
        options={{ label: "Pedidos" }}
        icon={Description}
        name="orders"
        list={OrderList}
      />
      <Resource
        options={{ label: "Permissões" }}
        icon={Warning}
        name="permissions"
        list={PermissionList}
        edit={PermissionEdit}
      />
      <Resource
        options={{ label: "Planos" }}
        icon={Description}
        name="plans"
        show={PlanShow}
        edit={PlanEdit}
        create={PlanCreate}
        list={PlanList}
      />
      <Resource
        options={{ label: "Posts do blog" }}
        icon={PostAdd}
        name="posts"
        show={PostShow}
        edit={PostEdit}
        create={PostCreate}
        list={PostList}
      />
      <Resource
        options={{ label: "Profissionais e horários" }}
        icon={AssignmentInd}
        name="user-services"
        list={UserServicesList}
      />
      <Resource
        options={{ label: "Respostas" }}
        icon={PostAdd}
        name="comments/replies"
      />
      <Resource
        options={{ label: "Salas" }}
        icon={MeetingRoom}
        name="rooms"
        create={RoomCreate}
        list={RoomList}
        show={RoomShow}
        edit={RoomEdit}
      />
      <Resource
        options={{ label: "Sugestões" }}
        icon={Feedback}
        name="sugestions"
        list={SugestionsList}
        show={SugestionsShow}
      />
      <Resource
        options={{ label: "Tags" }}
        icon={LocalOffer}
        name="tags"
        create={TagCreate}
        edit={TagEdit}
        list={TagList}
      />
      <Resource
        options={{ label: "Terapias" }}
        icon={RoomService}
        name="services"
        show={ServiceShow}
        edit={ServiceEdit}
        create={ServiceCreate}
        list={ServiceList}
      />
      <Resource
        options={{ label: "Usuários" }}
        icon={People}
        name="users"
        show={UserShow}
        edit={UserEdit}
        create={UserCreate}
        list={UserList}
      />
      <Resource
        options={{ label: "Taxas Fiscais" }}
        icon={AttachMoney}
        name="invoices/taxes"
        list={InvoiceTaxList}
        show={InvoiceTaxShow}
        create={InvoiceTaxCreate}
        edit={InvoiceTaxEdit}
      />
      <Resource
        options={{ label: "Notas Fiscais" }}
        icon={AttachMoney}
        name="invoices"
        list={InvoiceList}
        show={InvoicesShow}
      />
    </Admin>
  );
}

export default App;
