import * as React from 'react';
import { createElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery, List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { Assessment, PanTool, Email, People, GroupSharp, PersonOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    nestedMenuItem: {
        paddingLeft: theme.spacing(4),
    },
    nestedMenuItemIcon: {
        marginLeft: theme.spacing(2),
    },
}));

const Menu = ({ onMenuClick, logout }) => {
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const [openSubMenu, setOpenSubMenu] = useState(false);
    const [menuOpen, setMenuOpen] = useState({});

    const handleToggleSubMenu = () => {
        setOpenSubMenu(!openSubMenu);
    };

    const handleClick = (menu) => {
        setMenuOpen((prevOpen) => ({
            ...prevOpen,
            [menu]: !prevOpen[menu],
        }));
    };

    return (
        <div>
            <MenuItemLink
                to="#"
                primaryText="Clientes"
                leftIcon={<GroupSharp />}
                onClick={() => handleClick('clientes')}
                sidebarIsOpen={open}
            />
            <Collapse in={menuOpen.clientes} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <MenuItemLink
                        to="/socialSchedulesClients"
                        primaryText="Social"
                        leftIcon={<PersonOutlined />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={classes.nestedMenuItem}
                    />
                    <MenuItemLink
                        to="/schedules"
                        primaryText="Geral"
                        leftIcon={<PersonOutlined />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={classes.nestedMenuItem}
                    />
                </List>
            </Collapse>
            {resources.map(resource => (
                resource.name !== 'schedules' && resource.name !== 'socialSchedulesClients' && resource.name !== 'comments/replies' && (
                    <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={resource.options && resource.options.label || resource.name}
                        leftIcon={createElement(resource.icon)}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />
                )
            ))}
            <MenuItemLink
                to="/manual-operations"
                primaryText="Operações Manuais"
                leftIcon={<PanTool />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/relatorios"
                primaryText="Relatórios"
                leftIcon={<Assessment />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/marketing"
                primaryText="Marketing"
                leftIcon={<Email />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {isXSmall && logout}
        </div>
    );
}

export default withRouter(Menu);