import React, { useCallback, useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  BooleanInput,
  Filter,
  SelectInput,
  Toolbar,
  useNotify,
  useRefresh,
  SimpleForm,
  TextInput,
  SaveButton,
  FunctionField
} from "react-admin";

import './userServicesList.css';

import Modal from '@material-ui/core/Modal';
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import axios from 'axios';
import { Constants } from '../../constants';

const UserServicesList = (props) => {

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 700,
      height: 800,
      backgroundColor: theme.palette.background.paper,
      top: '7%',
      left: '30%',
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 3, 2),
      overflowY: 'scroll',
    },
  }));
  const classes = useStyles();

  const notify = useNotify();
  const refresh = useRefresh();
  const [modalState, setModalState] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [serviceChoices, setServiceChoices] = useState([]);
  const [servicesCurrentUser, setServicesCurrentUser] = useState([]);
  const [userServices, setUserServices] = useState([]);
  const [chosenService, setChosenService] = useState();
  const [serviceDetails, setServiceDetails] = useState([]);
  const [modalType, setModalType] = useState();

  const getServices = useCallback( async () => {
      try{
        const response = await axios.get(Constants.ApiUrl + '/services', { 
          params: { 
             range: '[0,1000]', 
             sort: `["id", "ASC"]`, 
             filter: {
              "status": "Ativo",
              "type": "GERAL"
            } 
          } 
        });
        const auxServices = response.data;

        const transformedServices = auxServices.map( (service) => {
          return { id: service.id, name: `${service.name}` }
        })

        setServiceChoices(transformedServices);

      }catch(err){
        console.log(err);
      }        
  }, []);

  const getServicesCurrentUser = useCallback( async (user) => {
    if(!user.id){
      return
    }
    try{
       const response = await axios.get(`${Constants.ApiUrl}/user-services/${user.id}`, { params: { range: '[0,1000]', sort: `["id", "ASC"]` } });
       const auxServices = response.data.serviceProfessionals;

       const transformedServices = auxServices.map( (service) => {
           return { id: service.id, name: `${service.name}` }
       })

       setServicesCurrentUser(transformedServices);

    }catch(err){
        console.log(err);
    }         
}, []);

  useEffect(() => {
    getServices();
  }, [getServices])

  useEffect(() => {
    setChosenService(undefined);
    setServiceDetails(undefined);
    getServicesCurrentUser(currentUser);
  }, [currentUser])

  const handleModalOpen = () => {
      setModalState(true);
  };

  const handleModalClose = () => {
      setModalState(false);
      setChosenService(undefined);
      setServiceDetails(undefined);  
  };

  const handleServiceChange = useCallback (async (e, user) => {
    await axios.get(`${Constants.ApiUrl}/user-services/details/`, { params: { user_id: user.id, service: e.target.value } })
      .then((response) => {
        setChosenService(e.target.value);
        setServiceDetails(response.data);
      })
      .catch((err) => {
        console.log(err);
        setChosenService(undefined);
      });
  }, [])

  const handleIndexUserServiceToRemove = useCallback (async (user) => {
    await axios.get(`${Constants.ApiUrl}/user-services/${user.id}`)
      .then((response) => {
        const auxServices = response.data.serviceProfessionals;

        const transformedServices = auxServices.map( (service) => {
          return { id: service.id, name: `${service.name}` }
      })

      setUserServices(transformedServices);
      })
      .catch((err) => {
        console.log(err);
      });
  })

  const ToolbarAddServices = props => (
    <Toolbar {...props} >
        <SaveButton onSave={(props) => {handleSubmitServiceToAdd(props)}} label="Confirmar"/>
    </Toolbar>
  );

  const ToolbarRemoveServices = props => (
    <Toolbar {...props} >
        <SaveButton onSave={(props) => {handleSubmitServiceToRemove(props)}} label="Remover"/>
    </Toolbar>
  );

  const ToolbarEditServiceDetails = props => (
    <Toolbar {...props} >
        <SaveButton onSave={(props) => {handleSubmitUserServiceDetails(props)}} label="Salvar"/>
    </Toolbar>
  );

  const hadleAddService = useCallback( async (record) => {
    setCurrentUser(record);
    setModalType('addService');
    handleModalOpen();
  }, [])

  const hadleRemoveService = useCallback( async (record) => {
    setCurrentUser(record);
    handleIndexUserServiceToRemove(record);
    setModalType('removeService');
    handleModalOpen();
  }, [])

  const handleEditServiceDetails = useCallback(async (record) => {
    setCurrentUser(record);
    setModalType('editServiceDetails');
    handleModalOpen();
  }, [])

  const AddServiceButton = ({ record }) => {
    return <Button color="secondary" onClick={() => hadleAddService(record)}>Permitir serviço</Button>;
  };

  const RemoveServiceButton = ({ record }) => {
    return <Button color="secondary" onClick={() => hadleRemoveService(record)}>Remover permissão</Button>;
  };

  const EditServiceDetailsButton = ({ record }) => {
    return <Button color="secondary" onClick={() => handleEditServiceDetails(record)}>Detalhes</Button>;
  };

  const handleSubmitServiceToAdd = async (modalData) => {
    const user = JSON.parse(localStorage.getItem('auth'));
    const config = {
        headers: {
            'Authorization': `Bearer ${user.token}`
        },
    }
    
    await axios
    .post(`${Constants.ApiUrl}/user-services/association`,
        {
            userId: currentUser.id,
            service: modalData.service,
            price: modalData.price,
            duration: modalData.duration,
            type: modalData.type,
        }, config)
        .then(() => {
            handleModalClose();
            notify('Permissão concedida com sucesso', 'info', {});
            refresh();
        })
        .catch((err) => {
            console.log(err);
            notify('Ocorreu um erro', 'warning', {});
        })
  }

  const handleSubmitServiceToRemove = async (modalData) => {
    const user = JSON.parse(localStorage.getItem('auth'));
    const config = {
        headers: {
            'Authorization': `Bearer ${user.token}`
        },
        params: { user_id: currentUser.id, service: modalData.service, }
    }
    
    await axios
    .delete(`${Constants.ApiUrl}/user-services`, config)
        .then(() => {
            handleModalClose();
            notify('Permissão removida com sucesso', 'info', {});
            refresh();
        })
        .catch((err) => {
            console.log(err);
            notify('Ocorreu um erro', 'warning', {});
        })
  }

  const handleSubmitUserServiceDetails = async (modalData) => {
    const user = JSON.parse(localStorage.getItem('auth'));
    const config = {
        headers: {
            'Authorization': `Bearer ${user.token}`
        },
    }
    
    await axios
    .put(`${Constants.ApiUrl}/user-services/details/${currentUser.id}`,
        {
          is_blocked: modalData.is_blocked,
          service: modalData.service,
          week_days: [
            {
              week_day_id: 0,
              initial_hour: modalData.initial_hour0,
              final_hour: modalData.final_hour0,
              lunch_initial_hour: modalData.lunch_initial_hour0 ? modalData.lunch_initial_hour0 : null,
              lunch_final_hour: modalData.lunch_final_hour0 ? modalData.lunch_final_hour0 : null,
              active: modalData.active0
            },
            {
              week_day_id: 1,
              initial_hour: modalData.initial_hour1,
              final_hour: modalData.final_hour1,
              lunch_initial_hour: modalData.lunch_initial_hour1 ? modalData.lunch_initial_hour1 : null,
              lunch_final_hour: modalData.lunch_final_hour1 ? modalData.lunch_final_hour1 : null,
              active: modalData.active1
            },
            {
              week_day_id: 2,
              initial_hour: modalData.initial_hour2,
              final_hour: modalData.final_hour2,
              lunch_initial_hour: modalData.lunch_initial_hour2 ? modalData.lunch_initial_hour2 : null,
              lunch_final_hour: modalData.lunch_final_hour2 ? modalData.lunch_final_hour2 : null,
              active: modalData.active2
            },
            {
              week_day_id: 3,
              initial_hour: modalData.initial_hour3,
              final_hour: modalData.final_hour3,
              lunch_initial_hour: modalData.lunch_initial_hour3 ? modalData.lunch_initial_hour3 : null,
              lunch_final_hour: modalData.lunch_final_hour3 ? modalData.lunch_final_hour3 : null,
              active: modalData.active3
            },
            {
              week_day_id: 4,
              initial_hour: modalData.initial_hour4,
              final_hour: modalData.final_hour4,
              lunch_initial_hour: modalData.lunch_initial_hour4 ? modalData.lunch_initial_hour4 : null,
              lunch_final_hour: modalData.lunch_final_hour4 ? modalData.lunch_final_hour4 : null,
              active: modalData.active4            
            },
            {
              week_day_id: 5,
              initial_hour: modalData.initial_hour5,
              final_hour: modalData.final_hour5,
              lunch_initial_hour: modalData.lunch_initial_hour5 ? modalData.lunch_initial_hour5 : null,
              lunch_final_hour: modalData.lunch_final_hour5 ? modalData.lunch_final_hour5 : null,
              active: modalData.active5
            },
            {
              week_day_id: 6,
              initial_hour: modalData.initial_hour6,
              final_hour: modalData.final_hour6,
              lunch_initial_hour: modalData.lunch_initial_hour6 ? modalData.lunch_initial_hour6 : null,
              lunch_final_hour: modalData.lunch_final_hour6 ? modalData.lunch_final_hour6 : null,
              active: modalData.active6
            },
          ]
        }, config)
        .then(() => {
            handleModalClose();
            notify('Horários alterados com sucesso', 'info', {});
            refresh();
        })
        .catch((err) => {
            console.log(err);
            notify('Ocorreu um erro', 'warning', {});
        })
  }

  //falta possibilidade de editar, provavelmente carregar quando selecionar o serviço e validação
  const bodyAddServices = (
    <div className={classes.paper}>
        <h2 id="simple-modal-title">Escolha o serviço para abilitar</h2>
        <SimpleForm submitOnEnter={false} toolbar={<ToolbarAddServices/>}>
            <SelectInput source="service" label="Serviço" choices={serviceChoices} />
            <TextInput source="price" label="Preço" />
            <TextInput source="duration" label="Duração em minutos" />
            <SelectInput source="type" label="Tipo" choices={[
            { id: 'Presencial', name: 'Presencial' },
            { id: 'Online', name: 'Online' },
        ]} />
        </SimpleForm>
    </div>
  );

  const bodyRemoveServices = (
    <div className={classes.paper}>
        <h2 id="simple-modal-title">Escolha o serviço para remover a permissão</h2>
        <SimpleForm submitOnEnter={false} toolbar={<ToolbarRemoveServices/>}>
            {
              userServices?.length > 0 ?
              <SelectInput source="service" label="Serviço" choices={userServices} /> :
              <span>Este profissional não tem permissões para terapias</span>
            }
        </SimpleForm>
    </div>
  );

  const bodyDetails = (
    <div className={classes.paper}>
        <h2 id="simple-modal-title">Escolha o serviço e edite os horários</h2>
        <SimpleForm submitOnEnter={false} toolbar={<ToolbarEditServiceDetails/>}>
        
        <BooleanInput source="is_blocked" label="Inativo" defaultValue={currentUser.is_blocked} />
            <SelectInput onChange={(service) => {handleServiceChange(service, currentUser)}} source="service" label="Serviço" choices={servicesCurrentUser} />
            {
              chosenService && serviceDetails?.length > 0 ?
              <table className="details-table">
                <tr>
                  <th>Atende</th>
                  <th>Dia da semana</th>
                  <th>Entrada</th>
                  <th>Saída</th>
                  <th>Início almoço</th>
                  <th>Fim Almoço</th>
                </tr>
                { serviceDetails?.map((detail) => {
                    return(
                      <tr key={detail.id}>
                        <td> <BooleanInput source={`active${detail.week_day_id}`} label="" initialValue={detail.active} /> </td>
                        <td className="td-day-name"> {detail.day_name} </td>
                        <td> <TextInput source={`initial_hour${detail.week_day_id}`} label="Entrada" initialValue={detail.initial_hour} /> </td>
                        <td> <TextInput source={`final_hour${detail.week_day_id}`} label="Saída" initialValue={detail.final_hour} /> </td>
                        <td> <TextInput source={`lunch_initial_hour${detail.week_day_id}`} label="Início almoço" initialValue={detail.lunch_initial_hour} /> </td>
                        <td> <TextInput source={`lunch_final_hour${detail.week_day_id}`} label="Fim almoço" initialValue={detail.lunch_final_hour} /> </td>
                      </tr>
                    )
                  })
                }
              </table>
              : chosenService === undefined ?
              <span>Selecione uma terapia acima para visualizar os detalhes</span>
              :
              <span>Este usuário não tem permissão para exercer esta terapia</span>
            }
        </SimpleForm>
    </div>
  );

  const ListFilter = props => (
    <Filter {...props}>
        <TextInput source="name" label="Nome" />
        <TextInput source="email" label="Email"/>
    </Filter>
  );

  const StatusField = (record) => {
    return (
      <Typography color={record?.is_blocked ? "textSecondary" : "secondary"}>
        {record?.is_blocked ? 'Inativo' : 'Ativo'}
      </Typography>
    )
  }

  return (
    <List title="Profissionais e serviços" filters={<ListFilter/>} filterDefaultValues={{ role: 'professional' }} {...props}>
      <>
        <Modal
          open={modalState}
          onClose={handleModalClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {
            modalType === 'editServiceDetails' ? bodyDetails :
            modalType === 'addService' ? bodyAddServices : bodyRemoveServices
          }
        </Modal>
        <Datagrid >
        <TextField source="name" label="Nome" />
        <TextField source="last_name" label="Sobre Nome" />
        <FunctionField label="Status" render={StatusField} />
        <AddServiceButton label="Add Permissões" />
        <RemoveServiceButton label="Del Permissões" />
        <EditServiceDetailsButton label="Editar horários" />
      </Datagrid>
      </>
    </List>
  );
};

export default UserServicesList;
